import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import Youtube from "components/youtube";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "storage/ssd_nvme_ahci_m2_pcie_sata"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/nvme-ahci-ssd.jpg"}) { ...eyecatchImg },
    sata: file(relativePath: { eq: "storage/sata.png"}) { ...normalImg },
    ssd_over_sata: file(relativePath: { eq: "storage/ssd_over_sata.png"}) { ...normalImg },
    pcie_ssd: file(relativePath: { eq: "storage/pcie_ssd.png"}) { ...normalImg },
    nvme_ssd: file(relativePath: { eq: "storage/nvme_ssd.png"}) { ...normalImg },
    nvme_initialize: file(relativePath: { eq: "storage/nvme_initialize.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`NVMe、AHCI、M.2など良く分からない人へ向けたSSDの選び方`}</h1>
    <time itemProp="datePublished" dateTime="Sat Dec 26 2020 10:41:28 GMT+0900 (GMT+09:00)">更新日:2020年12月26日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="NVMeとAHCIのSSD" mdxType="Image" />
    <p>{`SSDはSolid State Driveの略で、HDD(Hard Disk Drive、ハードディスクドライブ)と比べて物理的な動作を必要とせず、高速なデータ転送を行えるストレージである。`}</p>
    <p>{`HDDと比べて高価であるものの、高速で寿命が長く、軽量で故障に強いといった特徴を持っている。`}</p>
    <p>{`ところが一言でSSDといってもその種類は多く、インターネットで調べても専門的な記事が多すぎて結局何を買えばよいのかの判断がつかない場合も多いと思われる。`}</p>
    <p>{`そこでこの記事では分かりにくい`}<strong parentName="p"><em parentName="strong">{`NVMe`}</em>{`、`}<em parentName="strong">{`AHCI`}</em>{`、`}<em parentName="strong">{`M.2`}</em>{`、`}<em parentName="strong">{`SATA`}</em>{`、`}<em parentName="strong">{`PCIe`}</em>{`などの専門用語を整理しつつ、SSDの種類と用途、すなわち何がしたい時にどのSSDが適しているかを述べていきたい`}</strong>{`と思う。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "NVMe-M2-SSDとは何か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#NVMe-M2-SSD%E3%81%A8%E3%81%AF%E4%BD%95%E3%81%8B%EF%BC%9F",
        "aria-label": "NVMe M2 SSDとは何か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NVMe M.2 SSDとは何か？`}</h2>
    <p>{`宣伝文句として"NVMe M.2接続なので従来のものと比べて高速に動作します"などと謳われているが、NVMeやM.2とは何を意味するのだろうか？`}</p>
    <p>{`M2はフォームファクタで、NVMeはPCI Expressを通じて不揮発性ストレージメディアを接続する規格であるが、よく分からないと思うので、従来はどうであり、何が異なるのかなどを踏まえつつ整理していきたい。`}</p>
    <h3 {...{
      "id": "HDDはSATA接続しAHCIで制御",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#HDD%E3%81%AFSATA%E6%8E%A5%E7%B6%9A%E3%81%97AHCI%E3%81%A7%E5%88%B6%E5%BE%A1",
        "aria-label": "HDDはSATA接続しAHCIで制御 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDDはSATA接続しAHCIで制御`}</h3>
    <p>{`HDD(ハードディスクドライブ)は一般的に`}<em parentName="p">{`SATA(セイタ、Serial ATA(エーティーエー))`}</em>{`を通じてデータの読み書き命令が送られる。`}</p>
    <p>{`SATAはUSBなどと並ぶ接続規格の一つで、普及しているSATA 3.0では理論値として6Gbit/sのデータ転送速度を実現する事ができる。`}</p>
    <p>{`6Gbit/sは750MB（メガバイト)/sであるが、同期を取る目的で8b/10bという8ビットデータを10ビットデータに変換して送る伝送方式のため２割帯域をロスして`}<em parentName="p">{`600MB/sの理論値`}</em>{`を持つ。`}</p>
    <p>{`たとえば`}<strong parentName="p">{`3GBのファイルならば5秒で転送する能力`}</strong>{`を持つ。`}</p>
    <p>{`ところがHDDで大きなファイルのコピーをした人は実感があるかもしれないが、3GBのファイルをコピーするには30秒程度は掛かっているように思われる。`}</p>
    <p><strong parentName="p">{`HDDにSATA接続する場合には、`}<em parentName="strong">{`SATAの伝送帯域を十分使い切っておらず`}</em>{`、HDDそのものの遅さがファイルコピーの遅さにつながっている`}</strong>{`。`}</p>
    <p>{`別の言い方をすると、HDDがボトルネックとなって転送時間が伸びている。図示するとこんな感じ。`}</p>
    <Image {...props} name="sata" alt="SATA接続のHDD" mdxType="Image" />
    <p>{`ところでソフトウェアは読み書きの命令を送ることでHDDを動作させている。`}</p>
    <p>{`ここに命令の取り決めが必要で、取り決めが無ければ、ソフトウェアがHDDに画像情報を書き込みたいとして命令を出したとしても、HDDはその命令をうまく処理できず、データを保存する事はできない。`}</p>
    <p>{`よって命令の取り決めが必要となるのだが、この読み書き方法の命令・規則集にあたるものが`}<strong parentName="p"><em parentName="strong">{`AHCI(Advanced Host Controller Interface)`}</em></strong>{`である。`}</p>
    <p>{`ソフトウェアが接続線を通じてストレージと対話（やりとり）をするための論理的な取り決めであるため`}<strong parentName="p">{`AHCIは`}<em parentName="strong">{`論理インターフェース`}</em>{`の一種`}</strong>{`ということもできる。`}</p>
    <p>{`AHCIはSATA接続を通して命令の伝達が最適に行えるように設計されている。`}</p>
    <h3 {...{
      "id": "SSDでSATA接続しAHCIで制御を行う時の限界",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%A7SATA%E6%8E%A5%E7%B6%9A%E3%81%97AHCI%E3%81%A7%E5%88%B6%E5%BE%A1%E3%82%92%E8%A1%8C%E3%81%86%E6%99%82%E3%81%AE%E9%99%90%E7%95%8C",
        "aria-label": "SSDでSATA接続しAHCIで制御を行う時の限界 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDでSATA接続しAHCIで制御を行う時の限界`}</h3>
    <p>{`HDDからSSDに変わったとしても、SATA接続をしてAHCI制御で読み書きをすることが可能である。`}</p>
    <p>{`ただし、ここで問題となってくるのがSATAの伝送帯域である。`}</p>
    <p>{`SSDが登場した初期のころは問題とはならなかったが、高性能化が進むにつれ、6Gbit/sの伝送帯域では不十分となってしまった。`}</p>
    <p>{`言い換えると、ハードディスクの時には問題とはならなかった`}<strong parentName="p">{`SATAの伝送帯域が今度はボトルネックとなり、データ転送速度が遅くなる`}</strong>{`という事態が起きてしまったのである。`}</p>
    <p>{`パイプに水を流そうとするが、パイプの太さが問題となり水がより多く流れないというような問題である。`}</p>
    <Image {...props} name="ssd_over_sata" alt="SATAの限界を超えるSSD" mdxType="Image" />
    <h3 {...{
      "id": "SSDでPCI-Express接続しNVMeで制御を行うことで高速化を実現",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%A7PCI-Express%E6%8E%A5%E7%B6%9A%E3%81%97NVMe%E3%81%A7%E5%88%B6%E5%BE%A1%E3%82%92%E8%A1%8C%E3%81%86%E3%81%93%E3%81%A8%E3%81%A7%E9%AB%98%E9%80%9F%E5%8C%96%E3%82%92%E5%AE%9F%E7%8F%BE",
        "aria-label": "SSDでPCI Express接続しNVMeで制御を行うことで高速化を実現 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDでPCI Express接続しNVMeで制御を行うことで高速化を実現`}</h3>
    <p>{`そこでSATAよりも高速な伝送帯域を持つ`}<em parentName="p">{`PCIe(PCI Express)`}</em>{`という接続規格を用いてSSDと接続すれば良いということになった。`}</p>
    <p>{`PCI Express接続にはデータの通る道に該当するレーンが16本存在し、いくつのレーンを使うかで最大の転送速度が異なってくる。`}</p>
    <p>{`SSDの主流となっているのがレーンを４つ使った`}<em parentName="p">{`PCI Express 3.0x4`}</em>{`である。3.0はPCI Expressのバージョンで、１レーン当たり8Gbit/s、すなわち1GB/sを実現できる。`}</p>
    <p>{`4レーンあるため32Gbit/s、`}<strong parentName="p">{`すなわち4GB/sが理論的な最大転送速度`}</strong>{`である。`}</p>
    <p>{`1レーン当たり16GBit/sとPCIe3.0の２倍の転送速度を持つ`}<em parentName="p">{`PCIe4.0`}</em>{`も登場しており、これだと4レーンで8GB/sの転送速度を実現できる。`}</p>
    <Image {...props} name="pcie_ssd" alt="PCIeでSSDの通信を行う" mdxType="Image" />
    <p>{`これでSATAの限界である600MB/sの壁をめでたく突破できたわけであるが、まだPCIeの伝送帯域にはかなりの余裕がある。`}</p>
    <p>{`ここで、AHCIはSATA接続のHDDに向けて最適化されているが、物理的な構造が全く異なるSSDに対して十分な性能を引き出していないという問題があった。`}</p>
    <p>{`そこで`}<strong parentName="p">{`SSDに最適化された新たな論理インターフェースである`}<em parentName="strong">{`NVMe`}</em>{`が開発されることとなった。`}</strong></p>
    <p>{`NVMeは`}<em parentName="p">{`Non Volatile Memory Express`}</em>{`の略で、そのまま訳すとソフトウェアが不揮発性メモリと通信する規格といった意味になる。`}</p>
    <p><em parentName="p">{`Non Volatile`}</em>{`とは不揮発(ふきはつ)性の事である。液体が蒸発して気体となる性質を揮発性と呼び不揮発は蒸発しない性質のこと。この意味が転じて、`}<strong parentName="p">{`電源が切れてもデータが消失しない性質も`}<em parentName="strong">{`不揮発`}</em>{`である`}</strong>{`と言う。`}</p>
    <p>{`次に`}<em parentName="p">{`Memory`}</em>{`だが、SSDはUSBメモリと同じ`}<em parentName="p">{`NAND型フラッシュメモリ`}</em>{`で構成されており、パソコンの電源を落としてもデータを失わないため不揮発性メモリの一種である。`}</p>
    <p><em parentName="p">{`Express`}</em>{`は高速な通信といった意味で使われる。電車で〇〇Expressというと大抵は急行を意味するが、そのあたりを想像してもらえば良いだろう。`}</p>
    <p>{`要するに、`}<strong parentName="p"><em parentName="strong">{`NVMeは不揮発性メモリであるSSDと高速に通信するための規格`}</em></strong>{`ということである。`}</p>
    <Image {...props} name="nvme_ssd" alt="NVMe SSD" mdxType="Image" />
    <p>{`NVMe論理インターフェースの導入によりPCI Expressの伝送帯域に近い速度をSSDで実現することが可能となったのである。`}</p>
    <h3 {...{
      "id": "M2は接続端子や形状を表し、通信方式については関与しない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#M2%E3%81%AF%E6%8E%A5%E7%B6%9A%E7%AB%AF%E5%AD%90%E3%82%84%E5%BD%A2%E7%8A%B6%E3%82%92%E8%A1%A8%E3%81%97%E3%80%81%E9%80%9A%E4%BF%A1%E6%96%B9%E5%BC%8F%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E3%81%AF%E9%96%A2%E4%B8%8E%E3%81%97%E3%81%AA%E3%81%84",
        "aria-label": "M2は接続端子や形状を表し、通信方式については関与しない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`M.2は接続端子や形状を表し、通信方式については関与しない`}</h3>
    <p>{`次に`}<em parentName="p">{`M.2(エムドットツー)`}</em>{`とは何であろうか？NVMe M.2 SSDと書かれた製品が売られているためNVMeと何か関連しているのだろうか？などと思うかもしれないが、全く関係はしていない。`}</p>
    <p>{`M.2は`}<strong parentName="p"><em parentName="strong">{`フォームファクタ`}</em>{`、すなわち接続端子と形状を定めた規格`}</strong>{`である。`}</p>
    <p>{`M.2は`}<strong parentName="p">{`Type2280`}</strong>{`という形が主流で、これは横22mm(2.2cm)、縦80mm(8cm)という意味である。M.2 SSDは長方形の細長い板の形をしている。`}</p>
    <a href="https://www.amazon.co.jp/Type2280-PCIe3-0%C3%974-%E6%AD%A3%E8%A6%8F%E4%BB%A3%E7%90%86%E5%BA%97%E4%BF%9D%E8%A8%BC%E5%93%81-MZ-V7S500B-EC/dp/B07MZ4R9FR?pd_rd_i=B07MZ4R9FR&psc=1&linkCode=li3&tag=daigakuseitus-22&linkId=ffa7abdccb5b6aace01cc5c31b9316fc&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07MZ4R9FR&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=daigakuseitus-22&language=ja_JP" /></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=daigakuseitus-22&language=ja_JP&l=li3&o=9&a=B07MZ4R9FR" width="1" height="1" border="0" alt="" style={{
      "border": "none !important",
      "margin": "0px !important"
    }} />
    <p>{`他にもType2230、2242、2260、22110というタイプが存在するが、ほとんど販売はされていない。`}</p>
    <p>{`M.2はフォームファクタであるために、ソフトウェアとSSDとの通信手段には関与しない。すなわち、PCI Express接続でNVMe論理インターフェースを用いるか、SATA接続でAHCIを用いるかは定めておらず、実際両方の製品が販売されている。`}</p>
    <p>{`よって`}<strong parentName="p">{`M.2だから高速と結論付けるのは正しくはない`}</strong>{`。`}</p>
    <p>{`M.2以外のフォームファクタとしては下図のような`}<strong parentName="p"><em parentName="strong">{`2.5インチ`}</em></strong>{`の長方形のものがある。こちらは基本的にSATAでの接続を前提としている。`}</p>
    <a href="https://www.amazon.co.jp/Samsung-2-5-E3-82-A4-E3-83-B3-E3-83-81-MZ-77E500B-EC-E5-9B-BD-E5-86-85-E6-AD-A3-E8-A6-8F-E4-BF-9D/dp/B08SVN8MVP?&linkCode=li3&tag=daigakuseitus-22&linkId=9dfcfc95caf4ec2146810dc62d0cc0b7&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08SVN8MVP&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=daigakuseitus-22&language=ja_JP" /></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=daigakuseitus-22&language=ja_JP&l=li3&o=9&a=B08SVN8MVP" width="1" height="1" border="0" alt="" style={{
      "border": "none !important",
      "margin": "0px !important"
    }} />
    <h3 {...{
      "id": "NVMe-M2-SSDとは",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#NVMe-M2-SSD%E3%81%A8%E3%81%AF",
        "aria-label": "NVMe M2 SSDとは permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NVMe M.2 SSDとは`}</h3>
    <p>{`最初の問に戻ると、NVMe M.2 SSDとはPCI Express接続に最適化されたNVMe論理インターフェースを用いて通信を行い、物理的な形はM.2規格に基づいて作られているSSDということができる。`}</p>
    <p>{`最後に特徴を表にしてまとめると下のようになる。`}</p>
    <BasicTable mdxType="BasicTable">
  <caption>SSDの種類のまとめ</caption>
  <thead>
    <tr>
      <th>フォームファクタ</th>
      <th>通信規格</th>
      <th>論理インターフェース</th>
      <th>特徴</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td rowSpan="2">M.2</td>
      <td>PCI Express(PCIe)</td>
      <td>NVMe</td>
      <td>軽い、速い</td>
    </tr>
    <tr>
      <td>SATA (Serial ATA)</td>
      <td>AHCI</td>
      <td>軽い、遅い</td>
    </tr>
    <tr>
      <td>2.5インチ</td>
      <td>SATA (Serial ATA)</td>
      <td>AHCI</td>
      <td>重い、遅い</td>
    </tr>
  </tbody>
    </BasicTable>
    <h2 {...{
      "id": "NVMeはAHCIと比べてファイルコピーは速いがアプリの起動はそれほど速くならない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#NVMe%E3%81%AFAHCI%E3%81%A8%E6%AF%94%E3%81%B9%E3%81%A6%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%82%B3%E3%83%94%E3%83%BC%E3%81%AF%E9%80%9F%E3%81%84%E3%81%8C%E3%82%A2%E3%83%97%E3%83%AA%E3%81%AE%E8%B5%B7%E5%8B%95%E3%81%AF%E3%81%9D%E3%82%8C%E3%81%BB%E3%81%A9%E9%80%9F%E3%81%8F%E3%81%AA%E3%82%89%E3%81%AA%E3%81%84",
        "aria-label": "NVMeはAHCIと比べてファイルコピーは速いがアプリの起動はそれほど速くならない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NVMeはAHCIと比べてファイルコピーは速いがアプリの起動はそれほど速くならない`}</h2>
    <p>{`PCI Express接続のNVMeはSATA接続のAHCIよりも高速だが、その差はどこで体感できるのか？この問いに答える前に、まず`}<strong parentName="p"><em parentName="strong">{`シーケンシャルアクセス`}</em></strong>{`と`}<strong parentName="p"><em parentName="strong">{`ランダムアクセス`}</em></strong>{`を理解する必要がある。`}</p>
    <p>{`SSDに書き込みを行う時に、１つのファイルを書き込むか、断片化された複数のファイルを書き込むかで違いがある。`}</p>
    <p>{`想像するとなんとなく分かるかもしれないが、複数ファイルの書き込みは制御が複雑となり、１つのファイルを書き込む方が高速となる。`}</p>
    <p>{`また、読み込みを行う時も、様々な場所から複数のファイルを読み込むのと、一つの大きなファイルを読み込むのでは総合のサイズが同じであったとしても、連続した一つの大きなファイルを読み込む方が高速となる。`}</p>
    <p>{`この、読み書きのために`}<strong parentName="p">{`連続した領域に対してアクセスを行うことをシーケンシャルアクセス`}</strong>{`とよび、`}<strong parentName="p">{`断片化したいくつもの領域にアクセスを行うことをランダムアクセス`}</strong>{`とよぶ。`}</p>
    <p>{`複数のファイルを読み込むケースというのはアプリケーションやOS(オペレーティングシステム)を読み込む時であり、また大きなファイルを読み込むケースは写真や動画などをコピーする時である。`}</p>
    <p>{`すなわちアプリケーションやOSの読み込みではランダムアクセスが、ファイルのコピーではシーケンシャルアクセスが多く発生する。`}</p>
    <p><strong parentName="p">{`NVMeの特徴として、シーケンシャルアクセスは得意であるが、相対的にランダムアクセスは得意としていない`}</strong>{`。`}</p>
    <p>{`この結果`}<strong parentName="p">{`NVMeはOSやアプリの起動速度はAHCIとあまり変わらず、体感しやすい`}<em parentName="strong">{`アプリのサクサク感は向上しにくい`}</em></strong>{`。`}</p>
    <p>{`一方でファイルのコピー速度は速くなり、動画や写真のコピーの他、`}<em parentName="p">{`ゲームのロード時間もそれなりに短くなる`}</em>{`。`}</p>
    <h3 {...{
      "id": "SSDの速度の実際",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%AE%E9%80%9F%E5%BA%A6%E3%81%AE%E5%AE%9F%E9%9A%9B",
        "aria-label": "SSDの速度の実際 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDの速度の実際`}</h3>
    <p>{`次のYoutube動画によると各操作におけるHDD/AHCI SSD/NVMe SSDの速度は下のようになっている。`}</p>
    <p>{`NVMeのSSDはAHCIのSSDと比べて、アプリケーションの起動はそれほど高速化されておらず、OSの起動時には初期化に時間が掛かかるのか、かえって遅くなっている。`}</p>
    <p>{`これは、ランダムアクセスが多いという理由の他に`}<strong parentName="p">{`NVMeは初期化にかかるコストが高い`}</strong>{`という理由もあるようである。`}</p>
    <p>{`ただし、ファイルのコピー処理は明らかに高速化されている。動画中では明らかにされていないが、写真や動画ファイルをコピーしているのだと思われる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th></th>
      <th>OSの起動</th>
      <th>ゲームの起動</th>
      <th>Chrome(ブラウザ)<br />を起動してタブを５つ開く</th>
      <th>37GBのファイルコピー</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>HardDisk</td>
      <td>61秒</td>
      <td>45秒</td>
      <td>39秒</td>
      <td>498秒</td>
    </tr>
    <tr>
      <td>AHCI SSD</td>
      <td>36秒</td>
      <td>30秒</td>
      <td>5秒</td>
      <td>126秒</td>
    </tr>
    <tr>
      <td>NVMe SSD</td>
      <td>37秒</td>
      <td>29秒</td>
      <td>3秒</td>
      <td>38秒</td>
    </tr>
  </tbody>
    </BasicTable>>
    <Youtube mdxType="Youtube">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/EdF_aerWcW8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </Youtube>
    <h3 {...{
      "id": "NVMeは初期化処理が遅いの補足",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#NVMe%E3%81%AF%E5%88%9D%E6%9C%9F%E5%8C%96%E5%87%A6%E7%90%86%E3%81%8C%E9%81%85%E3%81%84%E3%81%AE%E8%A3%9C%E8%B6%B3",
        "aria-label": "NVMeは初期化処理が遅いの補足 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NVMeは初期化処理が遅いの補足`}</h3>
    <p>{`ランダムアクセスの処理はNVMeはシーケンシャルアクセス程は速くならないとは述べたものの、それでもAHCIのSSDと比べると速くはなっている。`}</p>
    <p>{`にも関わらず、OSの起動が速くならないのはどういうことか？これを図に表してみた。`}</p>
    <Image {...props} name="nvme_initialize" alt="NVMe SSD" mdxType="Image" />
    <p>{`実際にデータを送る前に、これからデータを送りますよという同意が基盤とSSDの間には必要となるが、この同意を確立するまでの時間がAHCIのSSDと比べて長いという事である。`}</p>
    <p>{`このような事はソフトウェアには良くある話で、専門用語で`}<em parentName="p">{`イニシャライズコストが高い`}</em>{`と呼んでいる。NVMeはAHCIと比べイニシャライズコストが高いのである。`}</p>
    <p>{`たとえ話でいうとウサギとカメみたいなもので、最初怠けているウサギ(NVMe)はカメ(AHCI)に起動速度で負けるのである。`}</p>
    <h2 {...{
      "id": "NVMeかAHCIどちらを選ぶべきか",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#NVMe%E3%81%8BAHCI%E3%81%A9%E3%81%A1%E3%82%89%E3%82%92%E9%81%B8%E3%81%B6%E3%81%B9%E3%81%8D%E3%81%8B",
        "aria-label": "NVMeかAHCIどちらを選ぶべきか permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NVMeかAHCIどちらを選ぶべきか`}</h2>
    <p>{`以上により情報が整理されたが、最後に主観的な意見を述べたいと思う。`}</p>
    <p>{`まず同価格であれば、文句なしでNVMeのSSDを購入するが、NVMeのSSDの方が若干高価ではある。`}</p>
    <p>{`HDDからAHCIのSSDに変えることと比べると、AHCIのSSDからNVMeのSSDに変えることは、ファイルのコピー速度以外それほどメリットが無いため、価格差ほどの恩恵が得られるかは分からない。`}</p>
    <p>{`ファイルのコピーは例えば動画編集ソフトに動画をインポートするときなどにも効果を発揮するはずなので、動画編集をよくする人で、かつ今までの動画編集が遅くていらいらしている人にとっては良い選択肢となるだろう。`}</p>
    <p>{`また、ゲームでマップデータをロードする処理はNVMeの恩恵を受けやすいため、3Dゲームをよくする人にとってもおすすめできる。`}</p>
    <p>{`結論として、`}<strong parentName="p">{`動画編集かゲームをよくする人は、価格と相談してNVMe SSD、オフィスソフトやインターネット閲覧が主な人は安価なAHCI SSD`}</strong>{`という事ができるだろう。`}</p>
    <p>{`補足としてゲームのロード時間はYoutube動画あるので紹介しておく。`}</p>
    <Youtube mdxType="Youtube">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/2AYextvB9l4" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </Youtube>
    <p>{`動画の内容を表にまとめると次のようになる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>ゲームタイトル</th>
      <th>NVMe SSD</th>
      <th>AHCI SSD</th>
      <th>SSHD</th>
      <th>HDD</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Shadow of the Tomb Raider</td>
      <td>12.46秒</td>
      <td>14.34秒</td>
      <td>31.46秒</td>
      <td>35.13秒</td>
    </tr>
    <tr>
      <td>Far Cry 5</td>
      <td>9.31秒</td>
      <td>10.20秒</td>
      <td>24.42秒</td>
      <td>28.52秒</td>
    </tr>
    <tr>
      <td>Assassin's Creed Odyssey</td>
      <td>27.15秒</td>
      <td>29.10秒</td>
      <td>50.39秒</td>
      <td>52.46秒</td>
    </tr>
    <tr>
      <td>The Witcher 3</td>
      <td>5.34秒</td>
      <td>5.34秒</td>
      <td>22.25秒</td>
      <td>22.34秒</td>
    </tr>
    <tr>
      <td>Kingdom Come Deliverance</td>
      <td>5.18秒</td>
      <td>5.35秒</td>
      <td>6.27秒</td>
      <td>16.18秒</td>
    </tr>
    <tr>
      <td>Battlefield 5</td>
      <td>69.25秒</td>
      <td>80.29秒</td>
      <td>83.09秒</td>
      <td>93.33秒</td>
    </tr>
    <tr>
      <td>Ghost Recon Wildlands</td>
      <td>11.16秒</td>
      <td>12.13秒</td>
      <td>22.05秒</td>
      <td>28.44秒</td>
    </tr>
    <tr>
      <td>GTA 5</td>
      <td>30.34秒</td>
      <td>41.58秒</td>
      <td>51.38秒</td>
      <td>56.47秒</td>
    </tr>
    <tr>
      <td>Resident Evil 2 Remake</td>
      <td>2.37秒</td>
      <td>2.51秒</td>
      <td>2.51秒</td>
      <td>3.06秒</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`NVMeにすることでおよそ10%程度高速化している。それにしてもゲームによりけりではあるが。`}</p>
    <p>{`また、フォームファクタについては触れていなかったが、`}<strong parentName="p">{`モバイルノートでは軽量化が重視されるため、軽量なM.2のSSDを搭載するのはあり`}</strong>{`である。`}</p>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`ここまで読み進めれば次の結論もチョットワカルようになったはずである。`}</p>
    <p>{`SSDは主流として、SATA接続のAHCI論理インターフェースを採用し、2.5インチフォームファクタを持つSSD(俗に`}<em parentName="p">{`SATA SSD`}</em>{`)とPCIe接続のNVMe論理インターフェースを採用し、M.2 Type2280のフォームファクタを持つSSD(俗に`}<em parentName="p">{`NVMe M.2 SSD`}</em>{`)が存在する。`}</p>
    <p>{`NVMe M.2 SSDは、OSやアプリの起動がランダムアクセスの遅さやイニシャライズコストが問題となりSATA SSDと比べてそれほど速くならないため、オフィスやブラウジング用途ではおすすめできない。`}</p>
    <p>{`一方でファイルのコピーが速いため、動画編集やゲームをよくする人にとっておすすめできる。あとは価格とにらめっこして、自分に適したSSDを決めていただければと思います。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      